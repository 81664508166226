import DateFormatter from '../../services/Utils';
import { GET_SETTINGS, SAVE_SETTINGS, SET_APP_CONFIG, SET_ERROR } from '../constant/types';

const initialState = {
  items: [],
  errors: {},
  notificationState: {},
  applicationConfig: {
    timezone: DateFormatter.selectedTimeZone,
  },
};

const SettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        items: action.payload?.setting,
        errors: {},
      };
    case SAVE_SETTINGS:
      return {
        ...state,
        items: action.payload?.setting,
        errors: {},
      };
    case SET_ERROR:
      return {
        ...state,
        items: [],
        errors: { message: action.payload.message },
      };

    case SET_APP_CONFIG:
      return {
        ...state,
        applicationConfig: action.payload,
        errors: {},
      };
    default:
      return state;
  }
};

export default SettingReducer;
