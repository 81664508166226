import { createSlice } from '@reduxjs/toolkit';
import http from '../../services/HttpClient';

const initialState = {
  widgets: [],
  widgetList: [],
  dashboardState: {
    sections: [],
  },
};

/**
 * DID-2.4.20, DID-2.4.21
 * @description
 * Dashboard state reducer
 */
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setWidgets: (state, action) => {
      state.widgets = action.payload.widgets;
      state.widgetList = action.payload.w;
      state.Widgets = action.payload.Widgets;
    },
    setDashboardState: (state, action) => {
      state.dashboardState = action.payload;
    },
  },
});

export const { setWidgets, setDashboardState } = dashboardSlice.actions;

export const fetchWidgets = () => async (dispatch) => {
  try {
    const response = await http.get('/api/nfc/v1/dashboard/getWidgets');
    dispatch(setWidgets(response.data.response));
  } catch (err) {
    console.log(err);
    // throw new Error(err);
  }
};

export const fetchDasboardState = () => async (dispatch) => {
  try {
    const response = await http.get('/api/nfc/v1/dashboard/getDashboardState');
    dispatch(setDashboardState(response.data.response.dashboardState));
  } catch (err) {
    console.log(err);
    // throw new Error(err);
  }
};

export const updateDasboardState = (newState) => async (dispatch) => {
  try {
    const response = await http.post('/api/nfc/v1/dashboard/saveDashboardState', newState);
    dispatch(setDashboardState(response.data.response.dashboardState));
  } catch (err) {
    console.log(err);
    // throw new Error(err);
  }
};

export default dashboardSlice.reducer;
