export const USER_AUTHENTICATION = 'USER_AUTHENTICATION';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_ERROR = 'SET_ERROR';
export const GET_TAG = 'GET_TAG';
export const USER_REGISTRATION = 'USER_REGISTRATION';
export const GET_USER = 'GET_USER';
export const SAVE_TAG = 'SAVE_TAG';
export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS';
export const CHANGE_TAG_STATUS = 'CHANGE_TAG_STATUS';
export const GET_SETTINGS = 'GET_SETTINGS';
export const SAVE_SETTINGS = 'SAVE_SETTINGS';
export const GET_REPORT = 'GET_REPORT';
export const GET_REPORTS = 'GET_REPORTS';
export const SEND_OTP = 'SEND_OTP';
export const VERIFY_OTP = 'VERIFY_OTP';
export const GET_SCAN_LIST = 'GET_SCAN_LIST';
export const GET_SCAN_DETAILS = 'GET_SCAN_DETAILS';
export const UPDATE_REPORT = 'UPDATE_REPORT';
export const GET_TOKEN = 'GET_TOKEN';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_TAG = 'UPDATE_TAG';
export const GET_ALERT_TAG_LIST = 'GET_ALERT_TAG_LIST';
export const RESET_STATE = 'RESEt_STATE';
export const GET_MANUFACTURER = 'GET_MANUFACTURER';
export const GET_GMID = 'GET_GMID';
export const NOTIFICATION_SETTINGS = 'NOTIFICATION_SETTINGS';
export const GET_ADMIN_STATUS = 'GET_ADMIN_STATUS';
export const GET_TAG_GROUP_BY_GMID_DATA = 'GET_TAG_GROUP_BY_GMID_DATA';
export const GET_VERIFIED_AND_UNVERIFIED_CHART_DATA = 'GET_VERIFIED_AND_UNVERIFIED_CHART_DATA';
export const GET_FLOW_OF_MATERIAL_DISTRIBUTION_DATA = 'GET_FLOW_OF_MATERIAL_DISTRIBUTION_DATA';
export const GET_ACTIVE_TAG_DATA_FOR_CHARTS = 'GET_ACTIVE_TAG_DATA_FOR_CHARTS';
export const GET_SCANNED_TAG_DATA_FOR_CHARTS = 'GET_SCANNED_TAG_DATA_FOR_CHARTS';
export const GET_TAG_DATA_BY_LOCATION_FOR_CHARTS = 'GET_TAG_DATA_BY_LOCATION_FOR_CHARTS';
export const GET_CHART_DATA_FOR_USER_BY_TIME = 'GET_CHART_DATA_FOR_USER_BY_TIME';
export const GET_CHART_DATA_FOR_USER_BY_ROLE = 'GET_CHART_DATA_FOR_USER_BY_ROLE';
export const GET_CHART_DATA_FOR_USER_BY_LOCATION = 'GET_CHART_DATA_FOR_USER_BY_LOCATION';
export const GET_CHART_DATA_FOR_USER_BY_OS = 'GET_CHART_DATA_FOR_USER_BY_OS';
export const GET_SCANS_GROUPED_BY_UNIDENTIFIED_CATEGORIES =
  'GET_SCANS_GROUPED_BY_UNIDENTIFIED_CATEGORIES';
export const GET_SCANS_BY_LOCATION_FOR_HEATMAP = 'GET_SCANS_BY_LOCATION_FOR_HEATMAP';
export const GET_DASHBOARD_COUNTER = 'GET_DASHBOARD_COUNTER';
export const SET_APP_CONFIG = 'SET_APP_CONFIG';

export const ADD_USER_MOVEMENT = 'ADD_USER_MOVEMENT';

export const GET_ALERT_COUNT_LIST = 'GET_ALERT_COUNT_LIST';
