import moment from 'moment-timezone';

export class DateFormatter {
  static selectedTimeZone = moment.tz.guess();

  /**
   *
   * @param {*} date
   * @returns
   */
  static formatToUTC(date) {
    return moment(date).tz(this.selectedTimeZone).format();
  }

  /**
   *
   * @param {*} date
   * @returns
   */
  static formatToLocale(date) {
    return date;
  }

  /**
   *
   * @returns
   */
  static getTimezones() {
    return moment.tz.names();
  }

  /**
   *
   * @param {*} timezone
   */
  static setTimeZone(timezone) {
    this.selectedTimeZone = timezone;
  }
}

export default DateFormatter;
