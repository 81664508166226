import Cookies from "js-cookie";
import http from "./HttpClient";

const LocalStorageService = (function(){
    let _service;
    let user={};
   
     function _getService() {
        if(Cookies.get('user')){
            let userDetail=Cookies.get('user') 
            let token=Cookies.get('token')

            
            user=JSON.parse(userDetail);  
            // console.log("session",user);
            if (!token) {
                http?.post("/api/nfc/v1/admin/getUserSession",{"_id":user.sessionID}).then((res)=>{
                    // console.log(res);
                    
                    if (!res.data.response.userSession) { 
                        user={};
                        Cookies.remove("user");
                        Cookies.remove("token");
                    }
                    // if (res.data.response.User.length && res.data.response.User[0].isActive==="Inactive") {
                    //     user={};
                    //     Cookies.remove("user");
                    //     Cookies.remove("token");
                    // }
    
                })
            }
            // else{
            //     user={}
            // } 
            }
           
        if(!_service) {
          _service = this;
          return _service
      }
      return _service
    }
    function _setToken(tokenObj) {
        Cookies.set('user', JSON.stringify(tokenObj));
        user=tokenObj;
    }
    function _getAccessToken() {
      let token=Cookies.get('token');
      return token
    }
    function _clearToken() {
      Cookies.remove("user");
      Cookies.remove("token");
    }

    function _getUserId(){
        return user?.id
    }
    function _getUserEmail(){
        return user?.email
    }
    function _getUserName(){
        return user?.name
    }
    function _getUserMobile(){
        return user?.mobile
    }
    function _getUserRole(){
       
        return user?.role
    }

    function _getNameId(){
       
        return user?.nameId
    }
    function _getSessionIndex(){
      
        return user?.sessionIndex
    }
    function _getAssertionId(){
  
        return user?.assertionId
    }
    function _getManufacturingLocation(){
  
        return user?.manufacturingLocation
    }

    function _isAuthenticated(){

        let token=Cookies.get('token');
        return token!=null
    }
   return {
      getService : _getService,
      setToken : _setToken,
      getAccessToken : _getAccessToken,
      getUserEmail:_getUserEmail,
      getUserMobile:_getUserMobile,
      getUserName:_getUserName,
      getUserRole:_getUserRole,
      getUserId:_getUserId,
      clearToken : _clearToken,
      isAuthenticated:_isAuthenticated,
      getNameId:_getNameId,
      getSessionIndex:_getSessionIndex,
      getAssertionId:_getAssertionId,
      getManufacturingLocation:_getManufacturingLocation
    }
   })();
   export default LocalStorageService;