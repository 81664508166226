import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loadable from '../layouts/loader/Loadable';
import ForgetPassword from '../views/auth/ForgetPassword';
import Loader from '../layouts/loader/Loader';


import PermissionService from '../services/PermissionService'
/****Layouts*****/
const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/

const Starter = Loadable(lazy(() => import('../views/Starter')));
const UserReport = Loadable(lazy(() => import('../views/ui/report/User')));
const ScanReport = Loadable(lazy(() => import('../views/ui/report/Scan')));
const TagReport = Loadable(lazy(() => import('../views/ui/report/Tag')));
// const AllScansReport = Loadable(lazy(() => import('../views/ui/report/AllScanReport')));
const UnidentifiedScans = Loadable(lazy(() => import('../views/ui/report/UnidentifiedScans')));
const UserList = Loadable(lazy(() => import('../views/ui/user/UserList')));
const AdminList = Loadable(lazy(() => import('../views/ui/user/AdminList')));
const UserAdd = Loadable(lazy(() => import('../views/ui/user/AddUser')));
const UserAccess = Loadable(lazy(() => import('../views/ui/user/UserAccess')));
const TagList = Loadable(lazy(() => import('../views/ui/tag/list')));
const TagGroup = Loadable(lazy(() => import('../views/ui/tag/group')));

const AddTag = Loadable(lazy(() => import('../views/ui/tag/addTag')));
const TagImport = Loadable(lazy(() => import('../views/ui/tag/import')));
const UserFeedback = Loadable(lazy(() => import('../views/ui/feedback/userFeedback')));
const NotificationSetting = Loadable(lazy(() => import('../views/ui/setting/notification')));
const Alert = Loadable(lazy(() => import('../views/ui/alerts/alerts')));
const Marketing = Loadable(lazy(() => import('../views/ui/marketing/marketing')));
const MarketingHistory = Loadable(lazy(() => import('../views/ui/marketing/list')));

const Scan = Loadable(lazy(() => import('../views/ui/scan/scan')));
const ScanDetail = Loadable(lazy(() => import('../views/ui/scan/scanDetail')));
const AppSetting = Loadable(lazy(() => import('../views/ui/setting/appSetting')));
const ManufacturingSetting = Loadable(lazy(() => import('../views/ui/setting/manufacturingList')));
const ManufacturingAddSetting = Loadable(lazy(() => import('../views/ui/setting/Manufacturing')));

const LogSetting = Loadable(lazy(() => import('../views/ui/setting/logSetting')));
const GmidSetting = Loadable(lazy(() => import('../views/ui/setting/gmidList')));
const GmidAddSetting = Loadable(lazy(() => import('../views/ui/setting/GMID')));

const AppInformationSetting = Loadable(lazy(() => import('../views/ui/setting/appInformation')));
const AddRole = Loadable(lazy(() => import('../views/ui/user/AddRole')));
const HeatMap = Loadable(lazy(() => import('../views/ui/report/Heatmap')));
const ReportDetail = Loadable(lazy(() => import('../views/ui/reportdetail/Detail')));

const AlertCount = Loadable(lazy(() => import('../views/ui/alertcount/alertCount')));


/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/NotAuthorized')));

const Login = Loadable(lazy(() => import('../views/auth/login')));
const TempLogin = Loadable(lazy(() => import('../views/auth/templogin')));
const Pivot = Loadable(lazy(() => import('../views/ui/report/pivot/Pivot')));

// const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));
/*****Routes******/


const permissionService = new PermissionService()

const ThemeRoutes = (isAuth) => {

  const isUserAccessListFetched = useSelector(state => state.access.isUserAccessListFetched)

  return [
    {
      path: '/',
      element: isAuth ? <FullLayout /> : <Navigate to="/auth/login" />,
      children: [
        { path: '/', exact: true, element:<Navigate to="/dashboard" /> },
        { path: '/dashboard', exact: true, element: <Starter /> },
        { path: '/scan', exact: true, element:permissionService.can(["API_GET_SCAN_LIST"]) && isUserAccessListFetched ? <Scan />:isUserAccessListFetched === null ? <Loader/>:<Error/> },
        { path: '/scan/detail/:id', exact: true, element:permissionService.can(["API_GET_SCAN_REPORT"]) && isUserAccessListFetched ? <ScanDetail />:isUserAccessListFetched === null ? <Loader/>:<Error/> },
        { path: '/alert', exact: true, element:permissionService.can(["API_GET_TAG_ALERT_LIST"]) && isUserAccessListFetched ? <Alert />:isUserAccessListFetched === null ? <Loader/>:<Error/> },
        { path: '/tag/list', exact: true, element:permissionService.can(["API_GET_TAG"]) && isUserAccessListFetched ? <TagList />:isUserAccessListFetched === null ? <Loader/>:<Error/> },
        { path: '/tag/group', exact: true, element:permissionService.can(["API_GET_TAG"]) && isUserAccessListFetched ? <TagGroup />:isUserAccessListFetched === null ? <Loader/>:<Error/> },

        { path: '/tag/add', exact: true, element: <AddTag /> },
        // { path: '/tag/import', exact: true, element: <TagImport /> },
        { path: '/tag/import', exact: true, element: permissionService.can(["API_SAVE_TAG"]) && isUserAccessListFetched ? <TagImport /> : isUserAccessListFetched === null ? <Loader/> : <Error/> },
        { path: '/report/user', exact: true, element:permissionService.can(["API_GET_USER_REPORT"]) && isUserAccessListFetched ? <UserReport />:isUserAccessListFetched === null ? <Loader/>:<Error/>},  
        { path: '/report/scan', exact: true, element: permissionService.can(["API_GET_SCAN_REPORT"]) && isUserAccessListFetched ? <ScanReport />:isUserAccessListFetched === null ? <Loader/>:<Error/> },  
        // { path: '/report/all-scan', exact: true, element: <AllScansReport /> },
  
        { path: '/report/scan/unidentified', exact: true, element:permissionService.can(["API_GET_SCAN_LIST"]) && isUserAccessListFetched ? <UnidentifiedScans />:isUserAccessListFetched === null ? <Loader/>:<Error/> },  
        { path: '/report/tag', exact: true, element:permissionService.can(["API_GET_TAG_REPORT"]) && isUserAccessListFetched ? <TagReport />:isUserAccessListFetched === null ? <Loader/>:<Error/> },  
        { path: '/report/heatMap', exact: true, element: <HeatMap /> },
        { path: '/reportdetail/detail', exact: true, element:permissionService.can(["API_GET_SCAN_DETAILS"]) && isUserAccessListFetched ? <ReportDetail />:isUserAccessListFetched === null ? <Loader/>:<Error/> },  
        { path: '/user/list', exact: true,element:permissionService.can(["API_GET_USER_LIST"]) && isUserAccessListFetched ? <UserList />:isUserAccessListFetched === null ? <Loader/>:<Error/> },  
        { path: '/admin/list', exact: true, element:permissionService.can(["API_GET_ADMIN_REPORT"]) && isUserAccessListFetched ? <AdminList />:isUserAccessListFetched === null ? <Loader/>:<Error/> },  
        { path: '/user/add', exact: true, element:permissionService.can(["API_UPDATE_USER"]) && isUserAccessListFetched ? <UserAdd />:isUserAccessListFetched === null ? <Loader/>:<Error/> }, 
        { path: '/user/role', exact: true, element: <UserAccess /> },
        
        { path: '/user/role/add', exact: true, element:permissionService.can(["API_GET_ROLE_LIST"]) && isUserAccessListFetched ? <AddRole />:isUserAccessListFetched === null ? <Loader/>:<Error/> },  
        { path: '/user/role/edit/:id', exact: true, element: <AddRole /> },
        { path: '/feedback', exact: true, element:permissionService.can(["API_GET_FEEDBACK"]) && isUserAccessListFetched ? <UserFeedback />:isUserAccessListFetched === null ? <Loader/>:<Error/> },   
        { path: '/marketing/send', exact: true, element:permissionService.can(["API_SEND_MARKETING_NOTIFICATION"]) && isUserAccessListFetched ?  <Marketing />:isUserAccessListFetched === null ? <Loader/>:<Error/> }, 
        { path: '/marketing/history', exact: true, element:permissionService.can(["API_GET_MARKETING_HISTORY"]) && isUserAccessListFetched ? <MarketingHistory />:isUserAccessListFetched === null ? <Loader/>:<Error/>},   

        { path: '/setting/notification', exact: true, element: permissionService.can(["API_SAVE_USER_NOTIFICATION"])  && isUserAccessListFetched ? <NotificationSetting />:isUserAccessListFetched === null ? <Loader/>:<Error /> },
        { path: '/setting/app', exact: true, element: permissionService.can(["API_SAVE_SETTING"])  && isUserAccessListFetched ? <AppSetting />:isUserAccessListFetched === null ? <Loader/>:<Error /> },
        { path: '/setting/log', exact: true, element: <LogSetting /> },
        { path: '/setting/manufacturing', exact: true, element: permissionService.can(["API_GET_MANUFACTURING_LIST"])  && isUserAccessListFetched ? <ManufacturingSetting />:isUserAccessListFetched === null ? <Loader/>:<Error/> },  
        { path: '/setting/manufacturingAdd', exact: true, element: permissionService.can(["API_SAVE_MANUFACTURING_SETTING"])  && isUserAccessListFetched ? <ManufacturingAddSetting />:isUserAccessListFetched === null ? <Loader/>:<Error/> },  
        { path: '/setting/gmid', exact: true, element: permissionService.can(["API_GET_GMID_LIST"])  && isUserAccessListFetched ? <GmidSetting />:isUserAccessListFetched === null ? <Loader/>:<Error/> }, 
        { path: '/setting/gmidAdd', exact: true, element: permissionService.can(["API_SAVE_GMID"])  && isUserAccessListFetched ? <GmidAddSetting />:isUserAccessListFetched === null ? <Loader/>:<Error/>}, 

        { path: '/alertCount', exact: true, element:permissionService.can(["API_GET_ALERT_COUNT_LIST"]) && isUserAccessListFetched ? <AlertCount />:isUserAccessListFetched === null ? <Loader/>:<Error/> },
        { path: '/report/pivot', exact: true,element:<Pivot/>  },

        { path: '/setting/information', exact: true, element: permissionService.can(["API_SAVE_SETTING"])  && isUserAccessListFetched ? <AppInformationSetting />:isUserAccessListFetched === null ? <Loader/>:<Error /> },
        { path: '*', element: <Navigate to="/auth/404" /> },
      ],
    },
    
    {
      path: '/auth',
      element: !isAuth ? <BlankLayout /> : <Navigate to="/dashboard" />,
      children: [
        { path: '404', element: <Error /> },
        { path: '*', element: <Navigate to="/auth/404" /> },
        { path: 'login', element: <Login /> },
        { path: 'login/templogin', element: <TempLogin /> },
        { path: 'recover', element: <ForgetPassword /> },
      ],
    },
  ];
}


export default ThemeRoutes;
